import { secureRefSplitApi } from './ref-api';

const continents = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchContinents: build.query<{ label: string; value: string }[], void>({
      query: () => ({ url: '/continents', method: 'GET' })
    }),
    fetchContinentCountries: build.query<
      { label: string; value: string }[],
      { continentId: string | number }
    >({
      query: ({ continentId }) => ({
        url: `/continent/${continentId}/countries`,
        method: 'GET'
      })
    })
  }),
  overrideExisting: false
});

export const { useFetchContinentsQuery, useFetchContinentCountriesQuery } =
  continents;
