import {
  createMigrate,
  PersistConfig,
  PersistedState,
  persistReducer,
  PersistState
} from 'redux-persist';
import { rootReducer } from './reducers';
import { clone, set } from 'lodash';
import { initialDashboardState } from './stores/buyer-dashboard';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

export type RootState = ReturnType<typeof rootReducer>;

const whitelistPersist: (keyof RootState)[] = [
  'buyer-dashboard',
  'agent-proforma',
  'metadata'
];

const migrations = {
  '0': (state: PersistedState) => {
    if (typeof state === 'undefined') {
      return { _persist: { version: -1, rehydrated: false } };
    }

    const newState = clone(
      state as {
        ['buyer-dashboard']: { pagination: { filter: {} } };
        _persist: PersistState;
      }
    );

    set(newState, 'buyer-dashboard', initialDashboardState);
    return newState;
  }
};

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage,
  whitelist: whitelistPersist as string[],
  version: 0,
  migrate: createMigrate(migrations, {
    debug: true
  })
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);
