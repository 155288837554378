import { axiosBaseQuery, createAxiosInstance } from '@client';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { LocationSearchResponse } from '@state/types/LocationSearchResponse';
import { CountryResponse } from '@module/country/model/CountryResponse';
import {
  BerthResponse,
  portlogApi,
  PortResponse,
  TerminalResponse
} from '@state';
import { getEnvironmentVariables } from '@common';
import { LocationData } from '@state/types/LocationData';
import { PaginatedData } from '@state/types/PaginatedData';

const { FORMULA_EXECUTOR_API_OVERRIDE, API_URL } = getEnvironmentVariables();

const axiosFormulaExecutionInstance = createAxiosInstance({
  baseURLParam: `${FORMULA_EXECUTOR_API_OVERRIDE ?? API_URL}/port-analysis`
});

export const locationApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: 'secured',
    customAxiosInstance: axiosFormulaExecutionInstance
  }),
  reducerPath: 'location-api',
  endpoints: (build) => ({
    searchLocation: build.query<LocationSearchResponse[], { location: string }>(
      {
        query: ({ location }) => ({
          method: 'GET',
          url: `/location/search`,
          params: { location: location }
        })
      }
    ),
    fetchLocation: build.query<
      LocationData,
      { locationId: number; type: string }
    >({
      query: ({ locationId, type }) => ({
        method: 'GET',
        url: `/location/${locationId}`,
        params: { type: type }
      })
    }),
    fetchLocationData: build.query<
      PaginatedData<LocationData>,
      { locationId: number; type: string; page: number; size: number }
    >({
      query: ({ locationId, type, page, size }) => ({
        method: 'GET',
        url: `/${type}/${locationId}/location_data`,
        params: { page: page, size: size }
      })
    })
  })
});

export type stateWithLocationApi = {
  [locationApi.reducerPath]: ReturnType<typeof locationApi.reducer>;
};

export const {
  useSearchLocationQuery,
  useLazySearchLocationQuery,
  useFetchLocationQuery,
  useLazyFetchLocationQuery,
  useFetchLocationDataQuery,
  useLazyFetchLocationDataQuery
} = locationApi;
