import { SupplierResponse } from '@module/supplier/model/SupplierResponse';
import { mainRestApi } from '../rest-api';
import { ContactResponse } from '@module/contact/model/ContactResponse';
import { PhoneResponse } from '@module/contact/model/PhoneResponse';

export interface SupplierProfileForm {
  id?: string;
  username?: string;
  name: string;
  surname: string;
  email: string;
}

interface AgentRegistrationSupplier extends SupplierResponse {
  contact: {
    phone1: PhoneResponse & {
      countryId: number;
      number: string;
      fullNumber: '+73216549870';
    };
    phone2?: PhoneResponse & {
      countryId: number;
      number: string;
      fullNumber: string;
    };
  };
  worldwide: boolean;
}

export const supplierApi = mainRestApi.injectEndpoints({
  endpoints: (build) => ({
    saveSupplierProfileSettings: build.mutation<
      SupplierProfileForm,
      SupplierProfileForm
    >({
      query: (data) => ({
        url: '/secured/supplier/settings/profile/save',
        method: 'POST',
        data
      })
    }),
    loadSupplierDetails: build.query<AgentRegistrationSupplier, void>({
      query: () => ({
        url: '/secured/supplier/load',
        method: 'GET'
      })
    })
  })
});

export const {
  useSaveSupplierProfileSettingsMutation,
  useLoadSupplierDetailsQuery
} = supplierApi;

export const loadSupplierRequestCompletedMatcher =
  supplierApi.endpoints['loadSupplierDetails'].matchFulfilled;
