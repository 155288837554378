import { ListenerMiddlewareInstance } from '@reduxjs/toolkit';
import { rootReducer } from '../../reducers';
import { loadSupplierRequestCompletedMatcher } from '../restApi/secured/supplierApi';
import { agentRegistrationSlice } from './reducers';

export const registerAgentAccountListeners = (
  listenerMiddleware: ListenerMiddlewareInstance<ReturnType<typeof rootReducer>>
) => {
  listenerMiddleware.startListening({
    matcher: loadSupplierRequestCompletedMatcher,
    effect: (action, { dispatch }) => {
      const { payload: supplier } = action;
      console.log(action);
      dispatch(
        agentRegistrationSlice.actions.setDetails({
          companyName: supplier.name,
          businessRegistrationNumber: supplier.businessRegistrationNumber,
          email: supplier.profile.email,
          siteUrl: supplier.siteUrl ?? '',
          firstName: supplier.name,
          lastName: supplier.profile.surname,
          address: supplier.address.street,
          postcode: supplier.address.postcode,
          city: supplier.address.city,
          countryId: supplier.address.country.id.toString(),
          phone1: {
            countryId: supplier.contact.phone1.countryId,
            number: supplier.contact.phone1.number,
            fullNumber: supplier.contact.phone1.fullNumber
          },
          phone2: {
            countryId: supplier.contact.phone2?.countryId ?? 0,
            number: supplier.contact.phone2?.number ?? '',
            fullNumber: supplier.contact.phone2?.fullNumber ?? ''
          }
        })
      );
      dispatch(
        agentRegistrationSlice.actions.setExpertise(
          (supplier.expertiseIds ?? []).slice()
        )
      );
      dispatch(
        agentRegistrationSlice.actions.setPorts(supplier.portsSelection ?? [])
      );
      dispatch(
        agentRegistrationSlice.actions.setIsWorldwide(supplier.worldwide)
      );
    }
  });
};
