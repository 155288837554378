import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BuyerDashboardFilterPagination } from '../../types';

export type BuyerDashboardState = {
  pagination: BuyerDashboardFilterPagination;
  drawer?: { portCallId: number; appointmentId: number };
};

export const initialDashboardState: BuyerDashboardState = {
  pagination: {
    page: 0,
    pageSize: 5,
    filter: {
      myPendingTasks: false,
      vessel: '',
      port: '',
      appointmentId: '',
      regionId: '',
      agent: ''
    }
  }
};

export const buyerDashboardSlice = createSlice({
  name: 'buyer-dashboard',
  initialState: initialDashboardState,
  reducers: {
    updatePage: (state, { payload }: PayloadAction<{ page: number }>) => {
      state.pagination.page = payload.page;
    },
    updateFilter: (
      state,
      { payload }: PayloadAction<BuyerDashboardFilterPagination['filter']>
    ) => {
      state.pagination.filter = payload;
      // reset page
      state.pagination.page = 0;
    },
    resetFilters: (state) => {
      state.pagination.filter = {
        myPendingTasks: false,
        vessel: '',
        port: '',
        appointmentId: '',
        regionId: '',
        agent: ''
      };
      // reset page
      state.pagination.page = 0;
    },
    updateDrawer: (
      state,
      { payload }: PayloadAction<{ portCallId: number; appointmentId: number }>
    ) => {
      state.drawer = payload;
    }
  }
});

export const {
  updatePage: updateBuyerDashboardPage,
  updateFilter: updateBuyerDashboardFilter,
  resetFilters: resetBuyerDashboardFilters,
  updateDrawer: updateBuyerDashboardDrawer
} = buyerDashboardSlice.actions;

export type StateWithBuyerDashboard = {
  [buyerDashboardSlice.name]: BuyerDashboardState;
};
