import { refSplitApi, secureRefSplitApi } from './ref-api';

const countriesApi = refSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchCountries: build.query<{ label: string; value: string }[], void>({
      query: () => ({ url: '/countries', method: 'GET' })
    })
  }),
  overrideExisting: false
});

const securedCountriesApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchCountryPorts: build.query<
      { label: string; value: string }[],
      { countryId: string | number; filter: string | undefined }
    >({
      query: ({ countryId, filter }) => ({
        // url: Boolean(filter)
        // ? `/country/${countryId}/filter/ports?filter=${filter}`
        url: `/country/${countryId}/filter/ports?filter=${filter}`,
        method: 'GET'
      })
    })
  }),
  overrideExisting: false
});

export const { useFetchCountriesQuery } = countriesApi;

export const { useFetchCountryPortsQuery } = securedCountriesApi;
